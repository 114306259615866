// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import activitySlice from "./reducers/activityReducer";
import candidateSlice from "./reducers/candidateReducer";
import pemifDataSlice from "./reducers/pemifReducer";
import firstEventSlice from "./reducers/eventReducer";
import roleSlice from "./reducers/roleReducer";
import restrictedActivitySlice from "./reducers/restrictedActivityReducer";
import exceptionActivitySlice from "./reducers/exceptionalActivityReducer";
import activityFromVafSlice from "./reducers/activityFromVafReducer";

const store = configureStore({
  reducer: {
    activity: activitySlice.reducer,
    activityFromVaf: activityFromVafSlice.reducer,
    candidates: candidateSlice.reducer,
    pemifData: pemifDataSlice.reducer,
    eventData: firstEventSlice.reducer,
    roles: roleSlice.reducer,
    restrictedActivityData: restrictedActivitySlice.reducer,
    exceptionActivity:exceptionActivitySlice.reducer
  },
});

export default store;
