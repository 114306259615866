import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNext,
  handleReviewPageError,
  handleBack,
  saveSubmit,
  handleAckPage,
  selectPemifData,
} from "../../store/reducers/pemifReducer";
import { resetActivity } from "../../store/reducers/activityReducer";
import { verifyAndUnlockUser } from "../../store/reducers/pemifReducer";
import { QualMapping } from "../../utils/constants";
import {
  resetCandidates,
  selectCandidates,
} from "../../store/reducers/candidateReducer";
import { resetRole } from "../../store/reducers/roleReducer";
import { useAuth } from "react-oidc-context";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { QualContext } from "../../context/QualContext";
import { Close } from "@mui/icons-material";
const StepActionsMarksSubmission = ({
  isError,
  isSubmit,
  isSuccess,
  isFirst,
  customStyle,
}) => {

  const auth = useAuth();
  const { user } = useContext(UserContext);
  const acessToken = auth.user?.access_token;
  const tokenExpiresAt = auth.user?.expires_at;
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const { candidates, candidate_details } = useSelector(selectCandidates);
  const navigate = useNavigate();
  const { activeStep, session_id } = useSelector(selectPemifData);
  const { isAckPage } = useSelector(selectPemifData);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { centre_number } = useContext(UserContext);
  const initialCandidatesRef = useRef(candidates);
  const initialCandidateDetailsRef = useRef(candidate_details);
  const location = useLocation();
  const qual = location?.state?.name;

  // Record the step we were at when we last saved, avoids multiple saves
  let lastSaveStep = 0;

  useEffect(() => {
    const hasChanges =
      JSON.stringify(initialCandidatesRef.current) !==
      JSON.stringify(candidates) ||
      JSON.stringify(initialCandidateDetailsRef.current) !==
      JSON.stringify(candidate_details);
    setHasUnsavedChanges(hasChanges);
  }, [candidates, candidate_details]);

  useEffect(() => {
    const handleLocationChange = () => {
      handleUnlockUser()
    };

    window.addEventListener('beforeunload', handleLocationChange);

    return () => {
      window.removeEventListener('beforeunload', handleLocationChange);
    };
  }, []);


  let candidateWithNoGender = [];
  (candidates||[]).forEach((val) => {
    if (!["M", "F"].includes(val.sex)) candidateWithNoGender.push(val);
  });
  const [autosaveIntervalId, setAutosaveIntervalId] = useState(null);
  const handleRest = () => {
    dispatch(handleReviewPageError(false));
    dispatch(resetCandidates());
    dispatch(resetActivity());
    dispatch(resetRole());
    handleUnlockUser();
    navigate("/home/markSubmission");
  };
  const handleSubmit = () => {
    handleSaveSubmit("submit")
      .then((res) => {
        res &&
          dispatch(handleAckPage());
      })
      .catch((e) => console.log("error", e));


  };
  const handleConfirm = () => {
    if (candidateWithNoGender.length)
      dispatch(handleReviewPageError(Boolean(candidateWithNoGender.length)));
    else dispatch(handleNext());
  };
  const handleSaveSubmit = async (operation) => {

    const postData = {
      ...candidate_details,
      pe_centre_contact_name: (candidate_details || {}).pe_centre_contact_name || ' ',
      candidate_details: candidates,
    };

    if (operation === "save") {
      // Preserve existing submit_indicator if already 'Y'
      postData.submit_indicator = "N";
    } else if (operation === "check")
    {
      // Validate form with backend
      postData.submit_indicator = "C";
    }
    else
    {
      postData.submit_indicator = "Y";
      postData.submitted_datetime = new Date().toISOString();
    }
    postData.loggedin_user = user?.name;
    try {
      const response = await dispatch(
        saveSubmit({ data: postData, token: acessToken })
      );
      if (operation === "check") {
        // For "check" operation, return true if there is an error (status 400)
        if (response.payload?.status === 400) {
          return true; // Indicates an error was found
        }
        return false; // Indicates no error was found
      }
      if (response?.payload === "Saved" || response?.payload === "Submitted")
        return true;
      else if (response.payload?.status === 400) return false;
      else return false;
    } catch (error) {
      return false;
    }
  };

  const handleDownload = async (saveChanges) => {
    const postData = {
      ...candidate_details,
      candidate_details: candidates,
    };

    if (saveChanges) {
      const saveSuccess = await handleSaveSubmit("save");
      if (!saveSuccess) return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MARK_BASEURL}markssubmissions/download`,
        {
          params: {
            centre_number: centre_number,
            qualification_id: qual,
            session_id: session_id,
          },

          headers: {
            Authorization: `Bearer ${acessToken}`,
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Marks_Submission_Data.xlsx");
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDownloadClick = () => {
    if (hasUnsavedChanges) {
      setOpenDialog(true);
    } else {
      handleDownload(false);
    }
  };

  useEffect(() => {
    if ((activeStep > 0) && (lastSaveStep != activeStep))
    {
      // Only save if we're past the first page and the active step has changed
      handleSaveSubmit("save");
      lastSaveStep = activeStep;
    }
  }, [activeStep, location]);



  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      handleSaveSubmit("save");
    }, 300000);

    setAutosaveIntervalId(autoSaveInterval);
    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [candidates, candidate_details,activeStep]);

  useEffect(() => {
    const handleBrowserClose = () => {
      handleUnlockUser();
      window.location.replace('/home/markSubmission');
    };
    window.addEventListener("popstate", handleBrowserClose);
    window.history.pushState(null, '');
    return () => {
      window.removeEventListener("popstate", handleBrowserClose);
    };
  }, []);

  useEffect(() => {
    const handleBrowserClose = () => {
      handleUnlockUser(); // Call your user unlock function
    };

    window.addEventListener("beforeunload", handleBrowserClose);

    return () => {
      window.removeEventListener("beforeunload", handleBrowserClose);
    };
  }, []);

  useEffect(() => {
    if (tokenExpiresAt) {
      const now = Date.now();
      const timeUntilExpiry = tokenExpiresAt * 1000 - now;

      const saveBeforeExpiration = timeUntilExpiry - 60000;
      if (saveBeforeExpiration > 0) {
        const timeoutId = setTimeout(() => {
          handleSaveSubmit("save");
          handleUnlockUser();
        }, saveBeforeExpiration);

        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [tokenExpiresAt, candidates, candidate_details]);

  useEffect(() => {
    const saveToLocalStorage = () => {
      localStorage.setItem(
        "marksSubmissionFormData",
        JSON.stringify({ candidates, candidate_details })
      );
    };

    saveToLocalStorage();
  }, [candidates, candidate_details]);

  const handleSave = async () => {
    const saveSuccess = await handleSaveSubmit("save");
    if (saveSuccess) {
      setOpenDialog(false);
      setHasUnsavedChanges(false);
      await handleDownload(true);
    }
  };

  const handleUnlockUser = async () => {
    let qualName = qual === QualMapping.ENTRY
      ? 'Entry'
      : qual === QualMapping.GCSE
        ? 'GCSE'
        : qual === QualMapping.ALEVEL
          ? 'A'
          : 'AS'
    await dispatch(
      verifyAndUnlockUser({
        centre: centre_number,
        token: auth.user?.access_token,
        qual: qualName,
      })
    );

  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.assign(
      `${process.env.REACT_APP_MY_CAMBRIDGE_LOGOUT_LINK}?redirect_uri=${process.env.REACT_APP_IDENTITY_LOGOUT_REDIRECT_URL}&client_id=${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
    );
  };
  const handleReload = () => {
    window.location.assign("/")
  }
  const handleSub = async () => {
    if (!isError()) {
      const isErrorResponse = await handleSaveSubmit("check");
      if (!isErrorResponse) {
        dispatch(handleNext());
      } else {
        console.error("An error was detected during the 'check' operation.");
      }
    }
  };
  return (
    <>
    {activeStep !==0 && <Divider variant="fullWidth" sx={{ mt: 3}} />}
      {isAckPage ? (
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mr: 1, width: "20%", textAlign: "center", height: "2.5rem" }}
            onClick={() => handleReload()}
          >
            Back to Home
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownloadClick}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "31.6px",
              width: "15%",
            }}
          >
            Download
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mr: 1, minWidth: "6rem", height: "10%" }}
            component={Link}
            onClick={handleLogout}
          >
            Sign out
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: isSuccess ? "flex-end" : "space-between",
            mt: 3,
            ...customStyle,
          }}
        >
          {isSuccess ? (
            <Button
              variant="contained"
              color="success"
              sx={{ mr: 1, width: "30%" }}
              component={Link}
              to="/"
            >
              Close
            </Button>
          ) : isFirst ? (
            <>
              <Button sx={{width: "10%"}} variant="contained" color="primary" onClick={handleRest}>
                Back
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 3,width: "10%" }}
                color="success"
                // disabled={isError()}
                onClick={() => (!isError() ? handleConfirm() : () => null)}
              >
                {"Confirm"}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleSaveSubmit("save").then(
                    (res) => res && dispatch(handleBack())
                  )
                }
                sx={{ mr: 1, width: "15%", color: "white" }}
              >
                {activeStep === 2 ? "Cancel" : "Back"}
              </Button>
              {activeStep !== 2 && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSave}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "31.6px",
                    width: "15%",
                  }}
                >
                  Download
                </Button>
              )}

              {activeStep === 1 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    // CASS-1051 : Save and Close is taking user back instead of the home page
                    handleSaveSubmit("save").then(
                      (res) => res && navigate("/")
                    );
                    handleUnlockUser()
                  }}
                  color="success"
                  sx={{ mr: 1, width: "15%" }}
                >
                  Save and Close
                </Button>
              )}

              {!isSubmit ? (
                <Button
                  sx={{ width: "15%", background: "#1e6807", color: "white" }}
                  variant="contained"
                  disabled={isError()}
                  onClick={handleSub}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  sx={{ width: "15%" }}
                  variant="contained"
                  color="success"
                  disabled={isError()}
                  onClick={() => (!isError() ? handleSubmit() : () => null)}
                >
                  Confirm Submission
                </Button>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default StepActionsMarksSubmission;
