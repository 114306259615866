import {
  MarkSubmissionPopup, VerifyUserPopup

} from "./SubmittedPopup"; // Import your custom component
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Grid, Button, Tooltip } from "@mui/material";
import { QualContext } from "../../context/QualContext";
import { UserContext } from "../../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { selectPemifData } from "../../store/reducers/pemifReducer";
import { fetchCandiates } from "../../store/reducers/candidateReducer";
import { resetActivity } from "../../store/reducers/activityReducer";
import { resetRole } from "../../store/reducers/roleReducer";
import { resetPemifData, verifyAndLockUser, verifyAndUnlockUser } from "../../store/reducers/pemifReducer";
import { exceptionActivityReset } from "../../store/reducers/exceptionalActivityReducer";
import { resetEventData } from "../../store/reducers/eventReducer";
import { markSubmissionDateRange, QualMapping } from "../../utils/constants";
import { useAuth } from "react-oidc-context";

import axios from "axios";

export const MarkLandingPage = ({ title, token }) => {
  const [tooltipOpen, setTooltipOpen] = useState({
    440: false,
    159: false,
    1787: false,
    97: false,
  });
  const [filledStatus, setFilledStatus] = useState({});
  const [selectedQual, setSelectedQual] = useState('');
  const [isActiveUser, setIsActiveUser] = useState(false);
  const { markSubmissionQual } = useContext(QualContext);
  const { centre_number, user } = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { session_id, verifyUserMessage } = useSelector(selectPemifData);

  let [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();
  const [activeUserErrorMessage, setActiveUserErrorMessage] = useState('');
  const currentDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();

  const checkFilledStatus = async (qualificationId) => {
    
    let submissionEndDate = qualificationId == 97
    ? new Date(new Date(markSubmissionDateRange.submissionEndEntry).setFullYear(currentDate.getFullYear()))
    : new Date(new Date(markSubmissionDateRange.submissionEnd).setFullYear(currentDate.getFullYear()));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getcandidatedetails?centre_number=${centre_number}&qualification_id=${qualificationId}&session_id=${session_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setFilledStatus((prev) => ({
        ...prev,
        [qualificationId]: currentDate >= submissionEndDate || !response.data.candidate_details?.length,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (centre_number && session_id) {
      markSubmissionQual.forEach((qual) => checkFilledStatus(qual.qualificationId));
    }
  }, [centre_number, session_id]);

  const handleMarkSubmission = async (event, qualificationId) => {
    let submissionEndDate = qualificationId == 97
    ? new Date(new Date(markSubmissionDateRange.submissionEndEntry).setFullYear(currentDate.getFullYear()))
    : new Date(new Date(markSubmissionDateRange.submissionEnd).setFullYear(currentDate.getFullYear()));

    const isFilled = filledStatus[qualificationId]; // Check if the qualification is already filled
    if (isFilled) {
      // Show tooltip for filled forms
      setTooltipOpen((prev) => ({
        ...prev,
        [qualificationId]: true,
      }));
      // return; // Prevent navigation if the form is filled
    }
    setTooltipOpen({
      440: false,
      159: false,
      1787: false,
      97: false,
    });
    dispatch(resetActivity());
    dispatch(resetRole());
    dispatch(resetPemifData());
    dispatch(exceptionActivityReset());
    dispatch(resetEventData());

    try {
      const res = await dispatch(
        fetchCandiates({
          centre: centre_number,
          qual: qualificationId,
          session: session_id,
          token: token,
        })
      );

      if ((currentDate >= submissionEndDate && (res.payload.submit_indicator && res.payload.submit_indicator != "N")) || !res.payload.candidate_details?.length) {
        // Show tooltip if form is already filled
        setTooltipOpen((prev) => ({
          ...prev,
          [qualificationId]: true,
          candidateCount: res.payload.candidate_details?.length,
          wasSubmitted: res.payload.submit_indicator,
          submittedBy: res.payload.pe_centre_contact_name,
          submittedDate: res.payload.submitted_datetime
        }));
      } else {
        // Navigate to the form if not filled
        setTooltipOpen({
          440: false,
          159: false,
          1787: false,
          97: false,
        });

        let qualName = qualificationId === QualMapping.ENTRY
        ? 'Entry'
        : qualificationId === QualMapping.GCSE
          ? 'GCSE'
          : qualificationId === QualMapping.ALEVEL
            ? 'A'
            : 'AS'
        const res = await dispatch(
          verifyAndLockUser({
            centre: centre_number,
            qual: qualName,
            session: session_id,
            token: auth.user?.access_token,
            user: user
          })
        )
        if (res?.payload) {
          if (res?.payload === 'User locked successfully.') {
            setActiveUserErrorMessage("")
            navigate("/home/markSubmission/form", {
              state: { name: event.target.name },
            });
          }
          else if (res?.payload !== 'User locked successfully.') {
            setActiveUserErrorMessage(res.payload)
            setIsActiveUser(true)
              // Show tooltip if there is any active user
              setTooltipOpen((prev) => ({
                ...prev,
                [qualificationId]: true,
              }));

            return true
          }
        } else {
          setActiveUserErrorMessage("")
          return false
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "nowrap",
          alignItems: "center",
          gap: "10px",
          height: "auto",
        }}
      >
        {title === "Marks submission" &&
          markSubmissionQual.map((qual, index) => {
            const isFilled = filledStatus[qual.qualificationId]; // Check if this qualification is already filled

            return (
              <Tooltip
                key={index}
                title={isActiveUser ? <VerifyUserPopup message={activeUserErrorMessage} /> : isFilled ? <MarkSubmissionPopup qual={qual} wasSubmitted={tooltipOpen.wasSubmitted} submittedBy={tooltipOpen.submittedBy} submittedDate={tooltipOpen.submittedDate} candidateCount={tooltipOpen.candidateCount} /> : ""} // Show tooltip only for filled forms
                arrow
                disableFocusListener
                placement="top"
                open={tooltipOpen[qual.qualificationId]}
                onClose={() => setTooltipOpen((prev) => ({ ...prev, [qual.qualificationId]: false }))} // Hide tooltip on close
              >
                <Button
                  key={index}
                  variant="contained"
                  name={qual.qualificationId}
                  onClick={(event) => {
                    setSelectedQual(qual.qualificationId[0])
                    setTooltipOpen(
                      {
                        440: false,
                        159: false,
                        1787: false,
                        97: false
                      })
                  }
                  }
                  sx={{
                    height: "3rem",
                    width: "20%",
                    minWidth: "20%",
                    margin: "0 10px", // Add some margin between buttons
                    borderRadius: selectedQual == qual.qualificationId[0] ? '4px' : '0px',
                    border: selectedQual == qual.qualificationId[0] ? '5px solid #C0C0C0' : '0px',
                    padding: selectedQual == qual.qualificationId[0] ? '8px 16px' : '0px',
                  }}
                >
                  {qual.qualificationDisplayNameButton}
                </Button>
              </Tooltip>
            );
          })}


      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
          marginTop: "20px"
        }}
      >{
          selectedQual && <Button variant="contained" sx={{ position: "absolute", right: "0", marginTop: "20px", backgroundColor: "#2E7D32" }} onClick={() => 
          handleMarkSubmission({ target: { name: selectedQual.toString() } }, selectedQual.toString())}>    Next
          </Button>
        }

      </Grid>
    </>

  );
};
