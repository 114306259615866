import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectPemifData } from "./pemifReducer";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";


export const fetchActivitiesFromVaf = createAsyncThunk(
    "activitiesFromVaf/fetchData",
    async ({ qual, token, session_id, centre_number, centre_name }) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}getvafform?session_id=${session_id}&centre_number=${centre_number}&centre_name=${centre_name}&qualification_id=${qual}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("API Response:", response.data);
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    }
  );
  

const activityFromVafSlice = createSlice({
  name: "activitiesFromVaf",
  initialState: {
    activitiesFromVaf: [],
    status: "idle",
    error: null,
  },
  reducers: {
    resetActivityFromVaf: state => {
      state.activitiesFromVaf = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchActivitiesFromVaf.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchActivitiesFromVaf.fulfilled, (state, action) => {
        console.log("action",action)
        state.status = "succeeded";
        state.activitiesFromVaf = action.payload;
      })
      .addCase(fetchActivitiesFromVaf.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })  
  },
});
export const { resetActivityFromVaf } = activityFromVafSlice.actions;
export const selectActivityFromVaf = state =>{ console.log("state.",state)
    return (state.activityFromVaf)};
export default activityFromVafSlice;
